import { Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import Container from "../../components/container"
import DocSearchContent from "../../components/docsearch-content"
import Layout from "../../components/layout"
import { itemListDocs } from "../../utils/sidebar/item-list"
import CompanyChart from "./company-chart"

class IndexRoute extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} itemList={itemListDocs}>
        <DocSearchContent>
          <Container>
            <Helmet>
              <title>Docs</title>
            </Helmet>
            <h1 id="gatsby-documentation" css={{ marginTop: 0 }}>
              公司介绍
            </h1>
            <p>北京祥龙物流（集团）有限公司</p>
            <h2>一、基本情况</h2>
            <p>
              北京祥龙物流有限公司于2003年9月以北京市大型物资运输公司为基础，对原北京市交通局所属的货运枢纽经营企业、货运代理企业、道路货物运输等企业按照现代企业制度重组改制注册的国有一人公司。2010年9月又与原北京市物资总公司下属的北京市恒物物流公司进行重组，成立北京祥龙物流（集团）有限公司。2014年初根据祥龙公司整体工作安排，祥龙物流与原北京一商局商业储运公司进行资产重组，公司的经营实力进一步加强。
            </p>
            <p>
              公司注册资本：5.99亿元，主要经营普通货物运输、大型物件运输、零担货物运输、仓储服务、货物配送、流通加工、钢材市场、销售百货、家用电器、家用电子产品、日用杂品、国际国内航空水运代理等。
            </p>
            <p>
              公司现有土地49宗，占地面积251万平方米，仓储面积34万平方米,运输车辆191部，吊车39部，叉车45部，铁路专用线7条。
            </p>

            <h1 id="gatsby-documentation" css={{ marginTop: 0 }}>
              组织机构及管理架构
            </h1>
            <CompanyChart />
          </Container>
        </DocSearchContent>
      </Layout>
    )
  }
}

export default IndexRoute
