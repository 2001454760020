import React from "react"
import Styles from "./organization.module.css"

const groupName = "北京祥龙物流（集团）有限公司"
const categoryNames = ["分公司", "子公司", "控股公司"]
const companyNames = [
  [
    "北京祥龙物流（集团）有限公司大件运输分公司",
    "北京祥龙物流（集团）有限公司物流配送分公司",
    "北京祥龙物流（集团）有限公司货场服务分公司",
  ],
  [
    "北京祥龙京南昌达物流有限公司",
    "北京祥龙一商物流有限公司",
    "北京祥龙三台山物流有限公司",
    "北京市商业储运有限公司",
    "北京祥龙化工物品储运有限公司",
  ],
  ["北京环捷物流有限公司", "辛集祥龙供应链管理有限公司"],
]

const width = 1240
const height = 660

const Background = () => (
  <rect
    width={String(width)}
    height={String(height)}
    style={{ fill: "#ddd" }}
  />
)

const rectStyle = {
  GROUP: Styles.group,
  CATEGORY: Styles.category,
  COMPANY: Styles.company,
}

const textStyle = {
  GROUP: Styles.short,
  CATEGORY: Styles.short,
  COMPANY: Styles.long,
}

const textPadding = 8
const textAdjustY = 2
const Label = ({ x, y, w, h, type, text }) => {
  let textX = "COMPANY" == type ? x + textPadding : x + w / 2
  let textY = y + h / 2 + textAdjustY

  if (text.startsWith(groupName) && text != groupName) {
    let pName = text.substring(groupName.length)

    return [
      <text
        key={"groupName"}
        x={textX}
        y={textY - 11}
        className={textStyle[type]}
      >
        {groupName}
      </text>,
      <text key={"pName"} x={textX} y={textY + 11} className={textStyle[type]}>
        {pName}
      </text>,
    ]
  } else {
    return (
      <text x={textX} y={textY} className={textStyle[type]}>
        {text}
      </text>
    )
  }
}
const Organization = ({ x, y, w, h, type, text }) => {
  let textX = "COMPANY" == type ? x + textPadding : x + w / 2

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={w}
        height={h}
        className={rectStyle[type]}
        rx={4}
        ry={4}
      />
      <Label x={x} y={y} w={w} h={h} type={type} text={text} />
    </g>
  )
}

const Architecture = () => {
  const xAxis = [100, 500, 900]
  const unitHeight = 48
  const unitWidth = 300
  const unitWidthNarrow = 120
  const offset = 20
  const baseLine1 = unitHeight * 2
  const baseLine2 = baseLine1 + unitHeight * 2
  const categoryList = xAxis.map((x, i) => {
    return (
      <Organization
        key={"category" + i}
        x={x - unitWidthNarrow / 2}
        y={baseLine1}
        w={unitWidthNarrow}
        h={unitHeight}
        type={"CATEGORY"}
        text={categoryNames[i]}
      />
    )
  })
  const categoryLinkShort = xAxis.map((x, i) => {
    return (
      <line
        key={"line" + i}
        x1={x}
        y1={(3 * baseLine1) / 4}
        x2={x}
        y2={baseLine1}
        className={Styles.link}
      />
    )
  })
  const categoryLink = () => {
    return categoryLinkShort.concat([
      <line
        key={"lineLong"}
        x1={xAxis[0]}
        y1={(3 * baseLine1) / 4}
        x2={xAxis[2]}
        y2={(3 * baseLine1) / 4}
        className={Styles.link}
      />,
      <line
        key={"lineShort"}
        x1={xAxis[1]}
        y1={baseLine1 / 2}
        x2={xAxis[1]}
        y2={(3 * baseLine1) / 4}
        className={Styles.link}
      />,
    ])
  }

  const companyList = xAxis.map((x, i) => {
    return companyNames[i].map((c, j) => {
      return (
        <Organization
          key={"company" + i + c}
          x={x + offset}
          y={baseLine2 + unitHeight * 2 * j}
          w={unitWidth}
          h={unitHeight}
          type={"COMPANY"}
          text={companyNames[i][j]}
        />
      )
    })
  })
  const companyLinkShort = xAxis.map((x, i) => {
    return companyNames[i].map((c, j) => {
      return (
        <line
          key={"line" + i + c}
          x1={x}
          y1={baseLine2 + unitHeight / 2 + unitHeight * 2 * j}
          x2={x + offset}
          y2={baseLine2 + unitHeight / 2 + unitHeight * 2 * j}
          className={Styles.link}
        />
      )
    })
  })
  const companyLink = () => {
    return companyLinkShort.concat(
      xAxis.map((x, i) => {
        let n = companyNames[i].length
        let y1 = baseLine2 - unitHeight
        return (
          <line
            key={"lineC" + i}
            x1={x}
            y1={y1}
            x2={x}
            y2={y1 + unitHeight * 2 * n - unitHeight / 2}
            className={Styles.link}
          />
        )
      })
    )
  }

  return (
    <g transform="translate(0, 20)">
      <Organization
        x={xAxis[1] - unitWidth / 2}
        y={0}
        w={unitWidth}
        h={unitHeight}
        type={"GROUP"}
        text={groupName}
      />
      {categoryList}
      {categoryLink()}
      {companyList}
      {companyLink()}
    </g>
  )
}

export default class CompanyChart extends React.Component {
  render() {
    const viewBoxString = "0 0 " + width + " " + height
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox={viewBoxString}
      >
        {/* <Background /> */}
        <Architecture />
      </svg>
    )
  }
}
